<template>
  <section>
    <div v-loading="loading"
      element-loading-text="Submitting the form, please wait..."
      element-loading-spinner="el-icon-loading"
    >
      <el-row class="navbar-head" v-if="getFormBuilder && getFormBuilder.customization && getFormBuilder.customization.show_title_bar">
        <!-- <el-col :span="24">
          <p v-if="getFormBuilder && getFormBuilder.name" class="navbar-title">
            {{ getFormBuilder.name }}
          </p>
        </el-col>-->
        <div class="mt-1 ml-1 logo">
          <a href="/dashboard">
            <img
              v-if="this.getSingleCompanyLogo"
              :src="this.getCompanyLogo"
              alt="Nimble logo"
              style="max-height: 34px; max-width: 150px"
            />
            <img
              v-else
              src="@/assets/img/logo-white.svg"
              alt="Nimble logo"
              class="img-fluid"
              style="max-height: 34px; max-width: 150px"
            />
          </a>
        </div>
      </el-row>
      <div class="success-body">
        <div class="success-card"> 
          <img
            src="@/assets/img/formbuilderSuccessIcon.svg"
            alt="icon"
            class="success-logo mt-2"
          />
          <p v-if="isEdited" class="success-head-text" >Updated Successful</p>
           <p v-else class="success-head-text">Submission Successful</p>
          <p class="success-sub-head" style="text-align: center" v-if="getUserMessage" v-html="finalThankyouText"></p>
          <p class="success-sub-head" v-else>Thanks! We have received your submission.</p>
          <div class="success-actions" v-if="this.$route.name !== 'ApplicaionFormbuilderCompleted'">
            <el-button
              type="text"
              @click="showMySubmissions"
              v-if="
                checkIsVisable && this.getFormBuilder.settings.edit_responses
              "
              >Edit my recent submission</el-button
            >
            <el-button
              type="text"
              @click="showMyAllSubmissions"
              v-if="checkIsVisable"
              >Show my submissions</el-button
            >
          </div>
        </div>
      </div>
      <div class="suggest-box">
        <div class="suggest-card" v-if="this.$route.name === 'ApplicaionFormbuilderCompleted'">
          <el-button @click="goBack">Go back</el-button>
          <el-button type="danger" @click="gotoDashboard">Go to dashboard</el-button>
        </div>
        <div class="suggest-card" v-else-if="this.$route.name === 'CompanyUserFormbuilderCompleted'">
          <el-button @click="goBack">Go back</el-button>
          <el-button type="danger" @click="gotoDashboard">Go to dashboard</el-button>
        </div>
        <div v-else class="suggest-card">
          <div v-if="this.getFormBuilder.customization.show_try_esigns_and_content">
          <p class="suggest-head">Get started with eSigns - It’s free!</p>
          <p class="suggest-text">
            Discover the most hassle-free experience of data management and
            custom forms. Get started with eSigns to unleash the great potential
            of automation workflows.
          </p>
          <el-button type="danger" @click="gotoSignIn">Try eSigns</el-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  data() {
    return {
      loading: false,
      step: 1,
      slug: null,
      layout: "div",
      employeeData: {},
      stepTypeMap: {
        COMPANY_BENEFIT: "Company Benefits",
        COMPANY_DOCUMENTS: "Company Documents",
        OFFER_LETTER: "Offer Letter",
        RELATIONAL_DATA: "Relational Data",
      },
      workflowCode: null,
      workflowDataId: null,
      userWorkflowStepsData: [],
      centerDialogVisible: true,
      thankyou_page: "You are completed Form builder by filling all details",
      isEdited : false,
      finalThankyouText: '',
    };
  },
  computed: {
    ...mapGetters("formbuilderData", [
      "getFormbuilderDataCreateByTemplateStatus",
      "getNewFormbuilderData",
      "getNewFormbuilderDataError",
    ]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getAllSteps() {
      return this.getFormBuilder.steps;
    },
    checkIsVisable() {
      return (
        this.getFormBuilder &&
        this.getFormBuilder.settings &&
        this.getFormBuilder.settings.view_responses
      );
    },
    getUserMessage() {
      return (
        this.getFormBuilder &&
        this.getFormBuilder.settings &&
        this.getFormBuilder.settings.success_and_redirect_page &&
        !this.getFormBuilder.settings.success_and_redirect_page.redirect_to &&
        this.getFormBuilder.settings.success_and_redirect_page.thankyou_page
      );
    },
      getCompanyLogo() {
      if (this.getSingleCompanyLogo) {
        return `${config.S3_BUCKET_URL}/${this.getSingleCompanyLogo}`;
      } else {
        return null;
      }
    },
  },
  async mounted() {
    if(this.$route && this.$route.query && this.$route.query.type && this.$route.query.type == 'edit'){
      this.isEdited = true
    }
    await this.getThankyouText();
  },
  methods: {
    async getThankyouText() {
      try {
        if (this.getFormBuilder?.settings?.success_and_redirect_page?.thankyou_page) {
          this.loading = true;
          let params = {
            formbuilder_data_id: this.getNewFormbuilderData._id, 
          }
          let responseData = await postAPICall(
            "GET",
            `/formbuilder-data/${this.getNewFormbuilderData._id}`,
            params
          );
          this.finalThankyouText = responseData?.data?.thank_you_text ?  responseData.data.thank_you_text : `Thank you for submitting the ${this.getFormBuilder.name} form`;
          this.loading = false;
        } else {
          this.finalThankyouText = 'Thank you for submitting the ' + (this.getFormBuilder?.name ? this.getFormBuilder.name : '') + ' form';
        }
         this.$nextTick(() => {
        this.resetStylesForInjectedContent();
      });
      } catch (err) {
        console.log("errrr",err);
      }
    },

  resetStylesForInjectedContent() {
    const ulElements = document.querySelectorAll('.success-sub-head ul');
    const liElements = document.querySelectorAll('.success-sub-head li');

    ulElements.forEach(ul => {
      ul.style.margin = '10px 0';
      ul.style.paddingLeft = '20px';
      ul.style.textAlign = 'left'; 
    });

    liElements.forEach(li => {
      li.style.listStyleType = 'disc';
      li.style.listStylePosition = 'inside';
    });
  },
    neverClose() {
      this.centerDialogVisible = true;
    },
    goBack(){
      window.close();
    },
    gotoDashboard(){
      if(this.$route.name === 'ApplicaionFormbuilderCompleted'){
        this.$router.push({
          path: "/ap/dashboard",
        });
      } else {
        this.$router.push({
          path: "/dashboard",
        });
      }
    },
    gotoSignIn() {
      this.$router.push({
        path: "/signup",
      });
    },
    showMyAllSubmissions() {
      this.$router.push({
        name: "GuestFormbuilderUserSubmittedDataList",
        params: {
          formbuilder_code: this.$route.params.formbuilder_code,
          formbuilder_details_id: this.$route.params.formbuilder_details_id,
          user_id: this.getAuthenticatedUser._id,
        },
      });
    },
    showMySubmissions() {
      this.hasEditMsg = 1
      if (this.getNewFormbuilderData) {
        this.$router.push({
          name: "GuestFormbuilderExecute",
          params: {
            formbuilder_details_id: this.$route.params.formbuilder_details_id,
          },
          query: {
            formbuilder_data_id: this.getNewFormbuilderData._id,
            stepId: 0,
            type : 'edit'
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-head {
  border-bottom: #cbd6e2 solid 1px;
  height: 69px;
  background-color: var(--primary-color) !important;
  .navbar-title {
    font-size: 18px;
    margin-top: 1%;
    margin-left: 2%;
    color: #677788;
    font-weight: 400;
  }
}
.success-body {
  margin-top: 25px;
  text-align: center !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .success-card {
    @media (min-width: 749px) {
      width: 749px;
      min-height: 260px;
    }
    width: 100%;
    min-height: 310px;
    height: 100%;
    border: 1px solid rgba(203, 214, 226, 0.5);
    .success-logo {
      width: 80px;
    }
    .success-head-text {
      font-weight: 500;
      font-size: 26px;
      color: #212529;
    }
    .success-sub-head {
      color: #303133;
      font-weight: 400;
      font-size: 16px;
    }
    .success-actions {
      display: inline !important;
    }
  }
}
.suggest-box {
  @media (min-width: 749px) {
    margin-top: 5px;
  }
  margin-top: 20px;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .suggest-card {
    @media (min-width: 749px) {
      width: 650px;
    }
    width: 100%;
    .suggest-head {
      color: #212529;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }
    .suggest-text {
      font-weight: 300;
      font-size: 16px;
    }
  }
}
</style>
